import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthenticationService } from "../../app/auth-connect/authentication.service";

export enum AuthStateStatus {
  pendingInitialRun,
  inProcess,
  idle,
  faulted
}

export interface AuthState {
  status: AuthStateStatus,
  isAuthenticated: boolean
}

const initialState: AuthState = { status: AuthStateStatus.pendingInitialRun, isAuthenticated: false };

export const getAuthenticationStatus: AsyncThunk<boolean, void, {}> = createAsyncThunk('authentication/getStatus', async () => {
  const authService: AuthenticationService = AuthenticationService.getInstance();
  return authService.isAuthenticated();
});


const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    authenticationStatusUpdated(state, action: { payload: boolean, type: string }) {
      state.isAuthenticated = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAuthenticationStatus.pending, (state, action) => {
        state.status = AuthStateStatus.inProcess;
      })
      .addCase(getAuthenticationStatus.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload;
        state.status = AuthStateStatus.idle;
      })
      .addCase(getAuthenticationStatus.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.status = AuthStateStatus.faulted;
      });
  }
});

export const { authenticationStatusUpdated } = authenticationSlice.actions;
export default authenticationSlice.reducer;