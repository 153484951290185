import { IonicAuthOptions } from '@ionic-enterprise/auth';
import fetch from 'node-fetch';
import { store } from '../app/store';
import {getConfig} from '@nve/node-cloud-config';

// This class is responsible to make calls to consul to fetch the application configurations
export class SpringCloudUtils {
  /**
   * Application Configuration is fetched
   */
   public static async getCloudConfigs(): Promise<{}> {
    // Fetching the application configuration from config server
    const configUrl = `${process.env.REACT_APP_CONFIG_URL}/${process.env.REACT_APP_CONFIG_PROFILE}`
    const config = await getConfig(configUrl);

    return config;
  }

  public static getApplicationConfig(key: string): any {
    let value = key.split(".").reduce((a,b) => a && a[b], store.getState().configuration.config);
    return value;
  }

  public static getIonicAuthOptions(configType: "web" | "native"): IonicAuthOptions {
    let options = {
      authConfig: SpringCloudUtils.getApplicationConfig("azure.auth") as any, // casting as 'any' because type validation against unions fails otherwise
      platform: SpringCloudUtils.getApplicationConfig(`azure.${configType}.platform`) as any,
      clientID: SpringCloudUtils.getApplicationConfig("azure.clientID"),
      discoveryUrl: SpringCloudUtils.getApplicationConfig("azure.discoveryUrl"),
      scope: SpringCloudUtils.getApplicationConfig("azure.scope"),
      redirectUri: SpringCloudUtils.getApplicationConfig(`azure.${configType}.redirectUri`),
      logoutUrl: SpringCloudUtils.getApplicationConfig(`azure.${configType}.logoutUrl`),
      implicitLogin: SpringCloudUtils.getApplicationConfig("azure.implicitLogin") as any,
      iosWebView: SpringCloudUtils.getApplicationConfig("azure.iosWebView") as any
    };
    if (configType === "web") {
      options["webAuthFlow"] = SpringCloudUtils.getApplicationConfig("azure.web.authFlow");
    }

    return options;
  }
}
