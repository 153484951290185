import { createSlice } from '@reduxjs/toolkit';

export const lookupsSlice = createSlice({
  name: 'lookups',
  initialState: { value: {} },
  reducers: {
    setLookups(state, action: { payload: unknown }) {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLookups } = lookupsSlice.actions;

export default lookupsSlice.reducer;
