import { createSlice } from '@reduxjs/toolkit';
import { ConnectionType } from 'ncp-graph-schema';

interface ConnectionTypeState {
  value: ConnectionType;
}
const initialState: ConnectionTypeState = {
  value: ConnectionType.ELECTRIC_SERVICE,
};

export const gasElectricToggleSlice = createSlice({
  name: 'gasElectricToggleConnectionType',
  initialState: initialState,
  reducers: {
    setConnectionType(state, action: { payload: ConnectionType, type: string }) {
      if (action.payload !== ConnectionType.GAS_SERVICE && action.payload !== ConnectionType.ELECTRIC_SERVICE) return;
      state.value = action.payload;
    },
    changeConnectionType: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      switch (state.value) {
        case ConnectionType.ELECTRIC_SERVICE: {
          state.value = ConnectionType.GAS_SERVICE;
          break;
        }
        case ConnectionType.GAS_SERVICE: {
          state.value = ConnectionType.ELECTRIC_SERVICE;
          break;
        }
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeConnectionType, setConnectionType } = gasElectricToggleSlice.actions;

export default gasElectricToggleSlice.reducer;
