import { createSlice } from '@reduxjs/toolkit';

export const isProjectRepresentativeSlice = createSlice({
  name: 'isProjectRepresentative',
  initialState: { value: false },
  reducers: {
    setIsProjectRepresentative(state, action: { payload: boolean }) {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsProjectRepresentative } = isProjectRepresentativeSlice.actions;

export default isProjectRepresentativeSlice.reducer;
