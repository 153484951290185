import { configureStore } from '@reduxjs/toolkit';
import gasElectricToggleConnectionTypeReducer from '../dashboards/slices/gas-electric-toggle.slice';
import authenticationReducer from '../shared/slices/authentication.slice';
import configurationReducer from '../shared/slices/configuration.slice';
import lookupsReducer from '../shared/slices/lookups.slice';
import isProjectRepresentativeReducer from '../new-service-initiation/design-initiation-acceptance/slices/is-project-representative.slice';

export const store = configureStore({
  reducer: {
    gasElectricToggleConnectionType: gasElectricToggleConnectionTypeReducer,
    authentication: authenticationReducer,
    configuration: configurationReducer,
    isProjectRepresentative: isProjectRepresentativeReducer,
    lookups: lookupsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
