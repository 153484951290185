import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { store } from './app/store';
import { Config } from './config';
import { configurationUpdated } from './shared/slices/configuration.slice';
import { SpringCloudUtils } from './spring-cloud-config/service.utils';
import { getConfig } from '@nve/node-cloud-config';
import { setLookups } from './shared/slices/lookups.slice';
const Root = lazy(() => import('./root'));

// Rendering application in ReactDOM.
const isBrowserSupported: boolean = (window as any).checkBrowserSupport();

if (isBrowserSupported) {
  SpringCloudUtils.getCloudConfigs()
    .then(response => {
      if (response) {
        // Store app config in redux
        store.dispatch(configurationUpdated(response));

        // Store app lookups in redux
        getConfig(Config.GetInstance().LookupsConfigUrl).then(value => {
          store.dispatch(setLookups(value));
        });

        // Get the system maintenance flag from the service.
        ((window as any).checkSystemMaintenance(Config.GetInstance().GQL_ENDPOINT) as Promise<
          any
        >).then(res => {
          // Check if the flag is enabled.
          // If it's not enabled, render the application.
          if (!res?.isEnabled) {
            ReactDOM.render(
              <Suspense fallback={''}>
                <Root />
              </Suspense>,
              document.getElementById('root')
            );
          }
        });
      }
    })
    .catch(error => {
      console.log(
        `Unable to retrieve configuration from server. ${process.env.REACT_APP_CONFIG_URL}/${process.env.REACT_APP_CONFIG_PROFILE}\n\n${error}`
      );
    });
}
