// Local environment config
import { IonicAuthOptions } from '@ionic-enterprise/auth';
import { SpringCloudUtils } from './spring-cloud-config/service.utils';
import { store } from './app/store';

export class Config {
  private static configInstance: Config = null;

  public static GetInstance(): Config {
    if (this.configInstance === null) {
      this.configInstance = new Config();
    }
    return this.configInstance;
  }

  public readonly ENV: string;
  public readonly GQL_ENDPOINT: string;
  public readonly RECAPTCHA_URL: string;
  public readonly RECAPTCHA_DISABLED_FORMS_URL: string;
  public readonly RECAPTCHA_SITEKEY: string;
  public readonly SERVICE_URL: string;
  public readonly FILE_UPLOAD_SERVICE_URL: string;
  public readonly FILE_DOWNLOAD_SERVICE_URL: string;
  public readonly AEM_CONTENT_SERVICE_URL: string;
  public readonly APP_BASE_URL: string;
  public readonly APOLLO_DEV_TOOL_FLAG: string;
  public readonly PAYMENT_URL: string;
  public readonly CLIENT_URL: string;
  public readonly IS_ADMIN_INSTANCE: boolean;
  public readonly azureWebConfig: IonicAuthOptions;
  public readonly azureNativeConfig: IonicAuthOptions;
  public readonly passwordResetUrl: string;
  public readonly mfaSelectionUrl: string;
  public readonly registerUrl: string;
  public readonly enableGqlQueryEncoding: string;

  // The endpoint to get the user journey feature flag.
  public readonly LOGGING_CONFIG_URL: string;

  // The endpoint to send the logs of user journey.
  public readonly LOGGING_URL: string;

  // When this field is true, you will not need to authenticate into the app
  // in order to access blocked routes
  public readonly BYPASS_AUTH_ROUTES: boolean;

  public readonly featureFlags: any;

  public readonly LookupsConfigUrl: string;

  constructor() {
    this.ENV = SpringCloudUtils.getApplicationConfig('spring.profiles');
    this.GQL_ENDPOINT = SpringCloudUtils.getApplicationConfig('gqlEndpoint');
    this.RECAPTCHA_URL = SpringCloudUtils.getApplicationConfig('recaptcha.url');
    this.RECAPTCHA_DISABLED_FORMS_URL = SpringCloudUtils.getApplicationConfig(
      'recaptcha.disabledFormsUrl'
    );
    this.RECAPTCHA_SITEKEY = SpringCloudUtils.getApplicationConfig('recaptcha.siteKey');
    this.SERVICE_URL = SpringCloudUtils.getApplicationConfig('serviceUrl');
    this.FILE_UPLOAD_SERVICE_URL = SpringCloudUtils.getApplicationConfig('fileUploadServiceUrl');
    this.FILE_DOWNLOAD_SERVICE_URL = SpringCloudUtils.getApplicationConfig(
      'fileDownloadServiceUrl'
    );
    this.AEM_CONTENT_SERVICE_URL = SpringCloudUtils.getApplicationConfig('aemContentServiceUrl');
    this.APP_BASE_URL = SpringCloudUtils.getApplicationConfig('azure.appBaseUrl');
    this.APOLLO_DEV_TOOL_FLAG = SpringCloudUtils.getApplicationConfig('azure.apolloDevToolFlag');
    this.PAYMENT_URL = SpringCloudUtils.getApplicationConfig('paymentUrl');
    this.CLIENT_URL = SpringCloudUtils.getApplicationConfig('clientUrl');
    this.IS_ADMIN_INSTANCE = SpringCloudUtils.getApplicationConfig('isAdminInstance');
    this.azureWebConfig = SpringCloudUtils.getIonicAuthOptions('web');
    this.azureNativeConfig = SpringCloudUtils.getIonicAuthOptions('native');
    this.passwordResetUrl = SpringCloudUtils.getApplicationConfig('passwordResetUrl');
    this.mfaSelectionUrl = SpringCloudUtils.getApplicationConfig('mfaSelectionUrl');
    this.registerUrl = SpringCloudUtils.getApplicationConfig('registerUrl');
    this.LOGGING_CONFIG_URL = SpringCloudUtils.getApplicationConfig('loggingConfigUrl');
    this.LOGGING_URL = SpringCloudUtils.getApplicationConfig('loggingUrl');
    this.BYPASS_AUTH_ROUTES = SpringCloudUtils.getApplicationConfig('bypassAuthRoutes');
    this.enableGqlQueryEncoding = SpringCloudUtils.getApplicationConfig('enableGqlQueryEncoding');
    this.featureFlags = SpringCloudUtils.getApplicationConfig('FeatureFlags');
    this.LookupsConfigUrl = SpringCloudUtils.getApplicationConfig('lookupsUrl');
  }

  static GetLookups(): unknown {
    return store.getState().lookups.value;
  }
}
