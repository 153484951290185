import { IonicAuth } from '@ionic-enterprise/auth';
import { isPlatform } from '@ionic/react';
import { Observable, Subject } from 'rxjs';
import { LocalStorage } from '../../app/storage/local-storage';
import { Config } from '../../config';
import { authenticationStatusUpdated } from '../../shared/slices/authentication.slice';
import { store } from '../store';

class AuthenticationService extends IonicAuth {
  private static instance: AuthenticationService = null;

  // keep track of what flow we're on when we return from mobile
  public currentFlow: 'login' | 'register' | 'resetPassword' | 'changeMfaSettings';

  public static getInstance() {
    if (this.instance === null) {
      this.instance = new AuthenticationService();
    }
    return AuthenticationService.instance;
  }

  private _loginStatusChanged: Subject<boolean>;
  get loginStatusChanged(): Observable<boolean> {
    return this._loginStatusChanged.asObservable();
  }

  private _loginErrorOccurred: Subject<string>;
  get loginErrorOccurred(): Observable<string> {
    return this._loginErrorOccurred.asObservable();
  }

  private localStorage: LocalStorage;

  constructor() {
    const config = isPlatform('hybrid') ? Config.GetInstance().azureNativeConfig : Config.GetInstance().azureWebConfig;
    super(config);

    this._loginStatusChanged = new Subject();
    this._loginErrorOccurred = new Subject();
    this.localStorage = LocalStorage.getInstance();

    // on web this will redirect user back to the profile from mfa settings
    if (window.location.search.includes('AADB2C90091')) {
      this.isAuthenticated().then(isAuthenticated => {
        if (isAuthenticated) {
          this.currentFlow = 'changeMfaSettings';
        } else {
          this.login();
        }
      });
    }
  }

  async login(): Promise<void> {
    try {
      // tell the azure pages to hold onto the session parameters for the return url
      // on mobile
      super.additionalLoginParameters({ loginFromApp: 'true' });
      this.localStorage.upsert('currentFlow', 'login');
      this.currentFlow = 'login';
      // override the discovery url to login. it may have been changed from a different
      this.setOverrideDiscoveryUrl(Config.GetInstance().azureNativeConfig.discoveryUrl);
      await super.login();
    } catch (error) {
      // On web we won't ever hit this code since we redirect away from the
      // page entirely
      console.log('login error: ', error);
      this.onLoginError(error);
    }
  }

  onLoginError(error) {
    // if on mobile the url is still the hash style
    if (isPlatform('hybrid')) {
      const message: string = error.message;
      if (message && message.startsWith('AADB2C90118')) {
        console.log('going to reset password mobile...');
        this.resetPassword();
      } else if (typeof error != 'object' && error.includes('AADB2C90091')) {
        // user is canceling out of changing their mfa settings on mobile
        return;
      } else if (typeof error == 'object' && error.toString().includes('AADB2C90091')) {
        // return to login on mobile if user cancels mfa on signup
        this.login();
      }
      // this error occurs when a user is trying to login on mobile
      // from a flow other than typical login
      else if (error != 'Not authenticated, refresh failed.') {
        this.throwError(message, error.error);
      }
    }
    // if on web the error is in query params because of the PKCE flow
    else {
      const urlParams = new URLSearchParams(window.location.search);
      const errorDescription = urlParams.get('error_description');
      if (errorDescription && errorDescription.startsWith('AADB2C90118')) {
        this.resetPassword();
      } else {
        this.throwError(errorDescription, error.error);
      }
    }
  }

  throwError(errorDescription, errorMessage) {
    // will redirect back to landing page from login page
    try {
      this._loginErrorOccurred.next(errorDescription);
      throw new Error(errorMessage);
    } catch (e) {
      console.log(e);
    }
  }

  onLoginSuccess() {
    // reauthenticate if they are logging in from the SU policy
    if (isPlatform('hybrid') && this.currentFlow == 'register') {
      this.login();
    } else {
      // observable status should be replaced with redux implementations
      this._loginStatusChanged.next(true);
      store.dispatch(authenticationStatusUpdated(true));
    }
  }

  onLogout() {
    // observable status should be replaced with redux implementations
    this._loginStatusChanged.next(false);
    store.dispatch(authenticationStatusUpdated(false));
  }

  async resetPassword(): Promise<void> {
    try {
      super.additionalLoginParameters({ loginFromApp: 'true' });
      this.currentFlow = 'resetPassword';
      await super.login(Config.GetInstance().passwordResetUrl);
    } catch (error) {
      // user has cancelled out in mobile, go back to login flow
      console.log('reset password error: ', error);
      this.currentFlow = 'login';
      this.onLoginError(error);
    }
  }

  async register(): Promise<void> {
    try {
      this.localStorage.upsert('currentFlow', 'register');
      this.currentFlow = 'register';
      super.additionalLoginParameters({ loginFromApp: 'true' });
      await super.login(Config.GetInstance().registerUrl);
    } catch (error) {
      // user has cancelled out in mobile, go back to login flow
      console.log('register error: ', error);
      this.currentFlow = 'login';
      this.onLoginError(error);
    }
  }

  async changeMfaSettings() {
    try {
      this.localStorage.upsert('currentFlow', 'changeMfaSettings');
      this.currentFlow = 'changeMfaSettings';
      super.additionalLoginParameters({ loginFromApp: 'true' });
      await super.login(Config.GetInstance().mfaSelectionUrl);
    } catch (error) {
      // user has cancelled out in mobile, go back to login flow
      console.log('change Mfa Settings error: ', error);
      this.currentFlow = 'login';
      this.onLoginError(error);
    }
  }

  async isAuthenticated(): Promise<boolean> {
    if (Config.GetInstance().ENV === 'local' && Config.GetInstance().BYPASS_AUTH_ROUTES) {
      store.dispatch(authenticationStatusUpdated(true));
      return true;
    }

    var isAuthed = await super.isAuthenticated();
    store.dispatch(authenticationStatusUpdated(isAuthed));
    return isAuthed;
  }
}

export { AuthenticationService };

