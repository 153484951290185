import { Storage } from './storage';

// LocalStorage implements the Storage interface
// This class is used to retrieve items from the browser's local storage.
class LocalStorage implements Storage {
  private static readonly instance: LocalStorage = new LocalStorage();

  public static getInstance() {
    return LocalStorage.instance;
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }

  public exists(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  public get(key: string): string {
    return localStorage.getItem(key) || '';
  }

  public upsert(key: string, value: string | boolean | number | object): void {
    // Get item from local storage.
    const currentItem = localStorage.getItem(key);

    // Check for item existence.
    if (currentItem) {
      // Remove item from storage.
      localStorage.removeItem(key);
    }

    // Save item in local storage.
    localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
  }
}

// This will export a singleton
export { LocalStorage };
