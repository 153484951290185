import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SpringCloudUtils } from "../../spring-cloud-config/service.utils";

export enum ResponseStatus {
  pending,
  complete
}

export interface ConfigurationState {
  status: ResponseStatus,
  config: {}
}

export const getConfiguration: AsyncThunk<{}, void, {}> = createAsyncThunk('configuration/getConfiguration', async () => {
  return SpringCloudUtils.getCloudConfigs();
});

const initialState = { status: ResponseStatus.pending, config: null };

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: initialState,
  reducers: {
    configurationUpdated(state, action: { payload: {}, type: string }) {
      state.config = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getConfiguration.fulfilled, (state, action) => {
      state.status = ResponseStatus.complete;
      state.config = action.payload;
    });
  }
});

export const { configurationUpdated } = configurationSlice.actions;
export default configurationSlice.reducer;